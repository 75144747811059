import { AspectRatio, ChakraProps, Text } from '@chakra-ui/react';
import { FC, FocusEvent, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { PlayCircle } from '@phosphor-icons/react';
import { useTranslation } from '../lib/hooks.context';
import { useOnScreen } from '../lib/hooks';
import { YoutubeVideEmbedProps } from '../types';

const Link = dynamic(() => import('./Link'));

const heroPreviewDuration = 30;

const youtubePartsRegEx =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;

const isString = (s: string) => !!s && typeof s === 'string';

const isYoutubeId = (url: string) =>
  isString(url) && !/^http(s?):\/\//.test(url) && url.length === 11;

const makeEmbed = (id: string) => `https://www.youtube.com/embed/${id}`;
const makeYoutubeLink = (id: string) => `https://www.youtube.com/watch?v=${id}`;

const makePreviewUrl = (embed: string, id: string, duration: number | string) =>
  `${embed}?autoplay=1${
    duration ? `&end=${duration}` : ''
  }&controls=0&mute=1&showinfo=0&disablekb=1&playsinline=1&rel=0&loop=1&playlist=${id}`;

const makeEmbedUrl = (url: string, heroMode = false): string[] => {
  // If is url
  let videoId: string;
  if (!isYoutubeId(url)) {
    const parts = url.match(youtubePartsRegEx);
    if (!parts?.length) {
      return [];
    }
    [, , videoId] = parts;
  } else {
    // most likely a youtube video id
    videoId = url;
  }

  const embed = makeEmbed(videoId);
  if (!heroMode) {
    return [embed];
  }
  const linkToVideo = makeYoutubeLink(videoId);
  return [makePreviewUrl(embed, videoId, heroPreviewDuration), linkToVideo];
};

const PreviewVideoLinkLabel = ({ sx, ...props }: ChakraProps) => {
  const t = useTranslation();

  return (
    <Text
      {...props}
      display="inline-flex"
      alignItems="center"
      textDecor="underline"
      sx={{ ...sx, svg: { marginLeft: 2 } }}
    >
      {t('hero_see_full_video_youtube')}
      <PlayCircle size="24" />
    </Text>
  );
};

const posAbsCanvas = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

const heroWrapperLinkHover = {
  ':before': {
    bg: 'rgba(0,0,0, .5)',
    transitionTimingFunction: 'ease-out',
  },
};
const HeroWrapper: FC<{ videoLink: string }> = ({ children, videoLink }) => {
  const [hover, onHover] = useState(false);

  return (
    <Link
      pos="relative"
      sx={{ iframe: { pointerEvents: 'none' } }}
      display="block"
      _before={{
        ...posAbsCanvas,
        content: "''",
        zIndex: 1,
        transition: 'background .325s ease-in',
      }}
      _hover={heroWrapperLinkHover}
      _focus={heroWrapperLinkHover}
      href={videoLink}
      onMouseOver={() => {
        onHover(true);
      }}
      onMouseOut={() => {
        onHover(false);
      }}
      onFocus={(e: FocusEvent) => {
        if (e?.stopPropagation) {
          e.stopPropagation();
        }
        onHover(true);
      }}
      onBlur={(e: FocusEvent) => {
        if (e?.stopPropagation) {
          e.stopPropagation();
        }
        onHover(false);
      }}
      borderRadius="lg"
      overflow="hidden"
    >
      {children}
      {hover ? (
        <PreviewVideoLinkLabel
          sx={posAbsCanvas}
          zIndex="2"
          margin="auto"
          color="white"
          width="max-content"
          height="min-content"
          pointerEvents="none"
        />
      ) : null}
    </Link>
  );
};

const YoutubeVideoEmbed = ({
  title,
  videoSrc,
  heroMode,
  isHidden = false,
}: YoutubeVideEmbedProps) => {
  const [embedUrl, videoLink] = makeEmbedUrl(videoSrc, heroMode);
  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useOnScreen(ref, '-10px');
  if (!embedUrl) {
    return null;
  }

  const content = (
    <AspectRatio
      ratio={16 / 9}
      sx={heroMode ? { iframe: { borderRadius: 'lg' } } : {}}
      ref={ref}
    >
      {isHidden ? (
        <span />
      ) : (
        <iframe
          title={title}
          src={embedUrl}
          frameBorder="0"
          allow="encrypted-media"
          allowFullScreen
          style={{
            display: isVisible || isVisible === null ? 'initial' : 'none',
          }}
        />
      )}
    </AspectRatio>
  );
  return heroMode && videoLink ? (
    <HeroWrapper videoLink={videoLink}>{content}</HeroWrapper>
  ) : (
    content
  );
};

export default YoutubeVideoEmbed;
